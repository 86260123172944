import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Form,
  Input,
  Row,
  Col,
  App,
  DatePicker,
  Divider,
  Image,
  Button,
  Table,
} from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/campaignRequest";
import { routes } from "routes";
import TextArea from "antd/es/input/TextArea";
import TableComponent from "../../components/table";
import { render } from "@testing-library/react";
import moment from "moment";

const CampaignRequestManage = () => {
  const [data, setData] = useState<any>({});
  const [dataAssets, setDataAssets] = useState<any>();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { message } = App.useApp();

  useEffect(() => {
    apiMethod
      .getAssetsById(`${id}`)
      .then((res: any) => {
        setDataAssets(res.data.result);
      })
      .catch((err: any) => { });
  }, []);

  const verifyRequestStatusChangeApi = (campDetails: any) => {

    let payload = {
      ...campDetails,
      creatorId: [campDetails?.creatorId],
      campaignId: data.id,
    }

    apiMethod
      .updateRequestStatusCreatorById(payload)
      .then((res: any) => {
        let temp = [...data.creators];
        let findIndexs = temp.findIndex((e: any) => e.id === payload?.creatorId[0]);
        if (findIndexs > -1) {
          temp[findIndexs].requestStatus = payload?.requestStatus;
        }
        setData({ ...data, requestStatus: payload?.requestStatus, creators: temp });
        message.success(res.data.message);
      })
      .catch((err: any) => {
        if (err && err.data && Array.isArray(err.data.message)) {
          form.setFields(
            err.data.message.map((e: any) => {
              return { name: e.key, errors: [e.msg] };
            })
          );
        } else {
          message.error(`${err.data.message}`);
        }
      });
  };

  console.log("data.creators : ", data.creators)

  return (
    <>
      <PageHeader
        title={
          id && data ? data["title"] : "New Campaign Request"}
        subTitle={"Campaign Request"}
      />
      <FormComponent
        routes={routes.campaignRequests}
        apiMethod={apiMethod}
        id={id}
        setData={setData}
        form={form}
        canSave={false}
        canNew={false}
        changeGetData={(obj: any) => {
          obj = { ...obj, ...obj.kpi };
          console.log(obj.creators, "obj.creators")
          obj.creators = obj.creators.map((e: any) => {
            var obj = e.creatorDetail;
            obj = {
              ...obj,
              requestedBy: e?.requestedBy,
              requestStatus: e?.requestStatus ?? 0,
              requestDate: e?.requestDate || '',
              requestReview: e?.requestReview || ''
            }
            obj.totalCost = `$${obj.totalCost ?? 0}`;
            return obj;
          });
          Object.keys(obj.reports).forEach((e) => {
            obj[`reports_${e}`] = obj.reports[e];
          });

          delete obj.kpi;
          return obj;
        }}
        dateChangeParam={["startedAt"]}
      >
        <div className={`max-w-full p-5 category-form`}>
          <div style={{ marginBottom: "26px" }}>Request Account Rep Review</div>
          {/* 
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item name="requestedBy" label="Requested By">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="requestDate" label="Date Submitted">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>

            <Col span={24} sm={12}>
              <Form.Item name="requestReview" label="Details">
                <TextArea
                  autoComplete="off"
                  disabled
                  autoSize={{ minRows: 4, maxRows: 5 }}
                // style={{ maxHeight: "147px", minHeight: "147px" }}
                />
              </Form.Item>
            </Col>

          </Row> */}
          {/* <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item >
                <div className="flex items-center space-x-4">
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClick={() => {
                      verifyRequestStatusChangeApi(2);
                    }}
                  >
                    Verify
                  </Button>
                  <Button
                    type="primary"
                    className="bg-red-500"
                    size="large"
                    block
                    onClick={() => {
                      verifyRequestStatusChangeApi(3);
                    }}
                  >
                    Reject
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item name="title" label="Title">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="campaignStatusName" label="Status">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="startedAt" label="Start Date">
                <DatePicker
                  autoComplete="off"
                  disabled
                  className="w-full"
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="budget" label="Budget">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="daps" label="Daps">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="days" label="Campaign Period">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="cpm" label="CPM">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="impressions" label="Impressions">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="mediaPlacements" label="Media Placements">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
          </Row>
          <label>Creators</label>
          {data.creators && (
            <TableComponent
              apiMethod={apiMethod}
              apiKeyNames={{ list: "list" }}
              data={{ data: data.creators }}
              isPagination={false}
              customAction={true}
              columns={[
                {
                  title: "Image",
                  dataIndex: "avatar",
                  sorter: false,
                  dataType: "round_image",
                  render: (avatar: any) => {
                    return avatar && <Image
                      height={40}
                      width={40}
                      className="object-cover	 rounded-full"
                      src={avatar}
                    />
                  }
                },
                {
                  title: "Name",
                  dataIndex: "agentFullName",
                  sorter: false,
                  dataType: "text",
                },
                {
                  title: "Company Name",
                  dataIndex: "companyName",
                  sorter: false,
                  dataType: "text",
                },
                {
                  title: "Requested By",
                  dataIndex: "requestedBy",
                  sorter: false,
                  dataType: "text",
                  render: (requestedBy: any) => {
                    return requestedBy
                  }

                },
                {
                  title: "Date Submitted",
                  dataIndex: "requestDate",
                  sorter: false,
                  dataType: "text",
                  render: (requestDate: any) => {
                    return requestDate ? moment(requestDate).format('MM-DD-YYYY') : '-';
                  }
                },
                {
                  title: "Details",
                  dataIndex: "requestReview",
                  sorter: false,
                  dataType: "text",
                  render: (requestReview: any) => {
                    return <span style={{ maxWidth: "600px", display: "block", minWidth: "150px", width: '100%' }}>{requestReview ? requestReview : "-"}</span>;
                  }

                },
                {
                  title: "Action",
                  dataIndex: "",
                  sorter: false,
                  dataType: "text",
                  render: (data: any) => {
                    console.log(data, "data")
                    return <>
                      {data?.requestStatus === 1 ?
                        <Form.Item >
                          <div className="flex items-center space-x-4">
                            <Button
                              type="primary"
                              size="large"
                              block
                              onClick={() => {
                                verifyRequestStatusChangeApi({
                                  requestStatus: 2,
                                  creatorId: data.id
                                });
                              }}
                            >
                              Verify
                            </Button>
                            <Button
                              type="primary"
                              className="bg-red-500"
                              size="large"
                              block
                              onClick={() => {
                                verifyRequestStatusChangeApi({
                                  requestStatus: 3,
                                  creatorId: data.id
                                });
                              }}
                            >
                              Reject
                            </Button>
                          </div>
                        </Form.Item>
                        : data?.requestStatus === 2 ?
                          <p style={{ color: "#50b848" }}>Verified</p>
                          : data?.requestStatus === 3 &&
                          <p style={{ color: "#ff4f4f" }}>Rejected</p>
                      }
                    </>
                  }
                },
                Table.EXPAND_COLUMN,
              ]}
              expandableView={(record: any) => {
                return (
                  <Row gutter={16}>
                    <Col span={24} sm={12}>
                      <Form.Item label="CCV">
                        <Input autoComplete="off" disabled value={record.ccv} />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Clicks">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.clicks}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Conversion">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.conversion}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Cost Per Click">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.costPerClick}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="CTA">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.cta}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Followers">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.followers}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Impressions">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.impressions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Total Cost">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.totalCost}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Total Viewers">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.totalviewers}
                        />
                      </Form.Item>
                    </Col>

                  </Row>
                );
              }}
            />
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Divider
                orientation="left"
                orientationMargin="0px"
                className="text-xl"
              >
                Assets
              </Divider>
            </Col>
          </Row>
          {dataAssets && (
            <>
              <Form.Item label="Banners or Logos">
                <Row gutter={16}>
                  <Col span={8} className="flex justify-center items-center">
                    <Image src={dataAssets.image1} className="object-contain" />
                  </Col>
                  <Col span={8} className="flex justify-center items-center">
                    <Image src={dataAssets.image2} className="object-contain" />
                  </Col>
                  <Col span={8} className="flex justify-center items-center">
                    <Image src={dataAssets.image3} className="object-contain" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Landing Page screenshot">
                <Row gutter={16}>
                  <Col span={8} className="flex justify-center items-center">
                    <Image
                      src={dataAssets.landingPageScreenshot}
                      className="object-contain"
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Row gutter={16}>
                <Col span={24} sm={12}>
                  <Form.Item label="Offer Link">
                    <Input
                      autoComplete="off"
                      disabled
                      value={dataAssets.offerLink}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Divider
                orientation="left"
                orientationMargin="0px"
                className="text-xl"
              >
                Reports
              </Divider>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item name="reports_impressions" label="Impressions">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item
                name="reports_mediaPlacements"
                label="Media Placements"
              >
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="reports_linkClicks" label="Clicks">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="reports_cpm" label="CPM">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="reports_brandReads" label="CTAs/Brand Reads">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="reports_engagementRate" label="Engagement Rate">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="reports_conversionRate" label="Conversion Rate">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </FormComponent>
    </>
  );
};

export default CampaignRequestManage;
