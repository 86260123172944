import { Form, App, FormInstance } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionBarComponent from "components/actionBar";
import dayjs from "dayjs";
import { RoutesInterface } from "routes";
import moment from "moment";

export interface FormComponentInterface {
  children: ReactNode;
  routes: RoutesInterface;
  apiMethod: any;
  id?: string;
  setData: (data: any) => void;
  form: FormInstance<any>;
  canSave: boolean;
  canNew: boolean;
  deleteMsgTitle?: string;
  deleteMsgDescription?: string;
  otherData?: any;
  dateChangeParam?: string[];
  canBack?: boolean;
  changeGetData?: any;
}

const FormComponent: React.FC<FormComponentInterface> = ({
  children,
  routes,
  apiMethod,
  id,
  setData,
  form,
  canSave,
  canNew,
  deleteMsgTitle,
  deleteMsgDescription,
  otherData,
  dateChangeParam,
  canBack = true,
  changeGetData,
}) => {
  const navigate = useNavigate();
  const [datas, setDatas] = useState({});
  const { message } = App.useApp();

  const onFinish = async (values: any) => {
    var obj = {};
    if (id) {
      obj = { ...datas, ...values };
    } else {
      obj = values;
    }
    callAddOrUpdate(id ? apiMethod.updateById : apiMethod.create, obj);
  };

  const callAddOrUpdate = (apiMethod: any, values: any) => {
    apiMethod({
      ...values,
      id: id ?? 0,
      ...(otherData ?? {}),
    })
      .then((res: any) => {
        setDatas({ ...res.data.result, ...values });
        setData({ ...res.data.result, ...values });
        message.success(res.data.message);
        navigate(routes.list!.path);
      })
      .catch((err: any) => {
        if (err && err.data && Array.isArray(err.data.message)) {
          form.setFields(
            err.data.message.map((e: any) => {
              return { name: e.key, errors: [e.msg] };
            })
          );
        } else {
          message.error(`${err.data.message}`);
        }
      });
  };

  const onDelete = async () => {
    apiMethod
      .deleteById(id)
      .then((res: any) => {
        message.success(res.data.message);
        navigate(routes.list!.path);
      })
      .catch((err: any) => { });
  };

  useEffect(() => {
    if (id) {
      apiMethod
        .getById(id)
        .then((res: any) => {
          var obj = (res.data.result?.data?.length > 0 && Array.isArray(res.data.result?.data)) ? res.data.result?.data?.[0] : res.data.result;
          if (changeGetData) {
            obj = changeGetData(obj);
          }
          Object.keys(obj).forEach((key) => {
            if ((dateChangeParam ?? []).includes(key) && obj[key]) {
              obj[key] = dayjs(obj[key]);
            }
          });
          setDatas(obj);
          setData(obj);
          form.setFieldsValue(obj);
        })
        .catch((err: any) => { });
    } else {
      setDatas({});
      setData({});
      form.resetFields();
    }
  }, [id]);

  return (
    <div className="px-5">
      <ActionBarComponent
        routes={routes}
        canBack={canBack}
        saveClick={
          canSave
            ? () => {
              form.submit();
            }
            : undefined
        }
        newClick={
          id && canNew
            ? () => {
              form.resetFields();
              navigate(routes.create!.path);
            }
            : undefined
        }
        deleteClick={
          id && deleteMsgTitle
            ? () => {
              onDelete();
            }
            : undefined
        }
        deleteMsgTitle={deleteMsgTitle}
        deleteMsgDescription={deleteMsgDescription}
      />
      <div className="bg-card my-2 rounded-md text-white">
        <Form
          layout="vertical"
          name={`${new Date().getMilliseconds}`}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          className="text-white"
        >
          {children}
        </Form>
      </div>
    </div>
  );
};

export default FormComponent;
