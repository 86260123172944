import PageContainer from "layouts/components/pageContainer";
import { apiMethod } from "api/campaigns";
import { routes } from "routes";
import TableComponent from "components/table";
import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import {
  EyeOutlined,
} from "@ant-design/icons";

const Campaigns = () => {

  const navigate = useNavigate();
  const columns = [

    {
      title: "Title",
      dataIndex: "title",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      sorter: false,
      dataType: "text",
      render: (brand: any) => {
        return <span>{brand?.brandEntityName}</span>
      }
    },
    {
      title: "Budget",
      dataIndex: "budget",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Days",
      dataIndex: "days",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Status",
      dataIndex: "campaignStatusName",
      sorter: false,
      dataType: "text",
    },
    {
      title: "Actions", dataType: "actions", width: 96, render: (row: any) => {
        return (
          <Button
            type="text"
            className="ant-btn-icon-only"
            onClick={() => {
              if (window.location.href.includes("campaignRequests")) {
                navigate(`${String(routes.campaignRequests.view?.path)?.replaceAll(":id", row.id)}`);
              } else {
                navigate(`${String(routes.campaigns.view?.path)?.replaceAll(":id", row.id)}`);
              }
            }}
          >
            <EyeOutlined />
          </Button>
        )
      }
    },
  ];

  return (
    <PageContainer
      title={window.location.href.includes("campaignRequests") ? "Campaign Requests" : "Campaigns"}
    >
      <TableComponent
        apiMethod={apiMethod}
        apiKeyNames={{ list: "list" }}
        columns={columns}
        customAction={true}
        viewRoute={window.location.href.includes("campaignRequests") ? routes.campaignRequests.view : routes.campaigns.view}
      />
    </PageContainer>
  );
};

export default Campaigns;
